(function ($, window, document, undefined) {

    'use strict';

    $(function () {

        // MENU

        $('.menu-opener').click(function(e){
            $('html').toggleClass('menu-opened');
        });

        // HEADER WHEN SCROLL

        $(window).scroll(function() {
            var scroll = $(window).scrollTop();
            if (scroll >= 15) {
                $('.header').addClass('scrolled');
            } else {
                $('.header').removeClass('scrolled');
            }
        });

        // SMOOTH SCROLL LINK


        $(document).on('click', 'a[href^="#"]:not(.video-opener)', function (event) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 50
            }, 500);
        });

        $(document).on('click', 'a.video-opener', function (event) {
            event.preventDefault();
            setHash($(this).attr('name'));
            launchVideo();
        });

        // VIDEO
        var videoIframe = $('.video-iframe');
        var firstPartVideo = 'https://player.vimeo.com/video/';
        var lastPartVideo = '?color=ead0c7&title=0&byline=0&portrait=0';

        function launchVideo(){
            var video = $(window.location.hash);
            if (video.length > 0){
                var videoData = $(video).attr('data');
                var videoName = $(video).attr('name');
                videoIframe.attr('src', firstPartVideo + videoData + lastPartVideo);
                setTimeout(function(){
                    $('.video-box').addClass('active');
                }, 150);
            }
        }

        launchVideo();


        $('.close').click(function(e){
            e.preventDefault();
            removeHash();
            if($('.video-box').hasClass('active')){
                $('.video-box').removeClass('active');
                setTimeout(function(){
                    videoIframe.attr('src', '');
                }, 150);
            }
        });


    });

})(jQuery, window, document);


function setHash(hash){
    var scrollV, scrollH, loc = window.location;
    if ("pushState" in history)
        history.pushState("", document.title, loc.pathname + loc.search + '#'+hash);
    else {
        // Prevent scrolling by storing the page's current scroll offset
        scrollV = document.body.scrollTop;
        scrollH = document.body.scrollLeft;

        loc.hash = "";

        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
    }
}

function removeHash(){
    var scrollV, scrollH, loc = window.location;
    if ("pushState" in history)
        history.pushState("", document.title, loc.pathname + loc.search);
    else {
        // Prevent scrolling by storing the page's current scroll offset
        scrollV = document.body.scrollTop;
        scrollH = document.body.scrollLeft;

        loc.hash = "";

        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV;
        document.body.scrollLeft = scrollH;
    }
}